import styled from 'styled-components';
export const PictogramContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const Title = styled.button `
  display: flex;
  align-items: flex-start;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  padding: 2px 0 10px 0;
  white-space: nowrap;
  @media (min-width: 901px) {
    &:hover {
      cursor: pointer;
    }
  }
  ${(props) => props.theme.mixins.mobile(`
    font-size: 16px; 
    padding-bottom: 15px;
  `)};
`;
export const IconImg = styled.img `
  height: 76px;
  margin-bottom: 15px;
  @media (min-width: 901px) {
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: 0.3s;
    }
  }
  @media (max-width: 1200px) {
    height: 60px;
  }
  ${(props) => props.theme.mixins.mobile('height: 50px;')};
`;
export const Text = styled.p `
  font-size: 14px;
  text-align: center;
  margin: 0;
  white-space: pre-wrap;
`;
export const PictogramLink = styled.a `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
