import { __awaiter } from "tslib";
import { ThumbnailUtils } from '@grupakmk/libstorefront';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import LSF from '@@Storefront/index';
import { Spinner } from '@@Components/Spinner';
import { BlogService } from '@grupakmk/libstorefront-blog-plugin';
import { MainContainer } from '@@Components/MainContainer';
import { Breadcrumbs } from '@@Components/Breadcrumbs';
import { BreadcrumbElementWrapper } from '@@Components/Breadcrumbs/styled';
import { getBlogCategories } from '@@App/src/lib/storefront/actions/blog';
import { blogCategories } from '@@App/src/lib/storefront/selectors/blog';
import { RelatedArticles } from '@@Components/RelatedArticles';
import { Icon } from '@@Components/Icon/Icon';
import { useLang } from '@@Hooks/useLang';
import { useRouter } from '@@Hooks/useRouter';
import { useSelector } from 'react-redux';
import { Button } from '@@Components/Buttons/Button';
import dayjs from 'dayjs';
import { PageSeo } from '@@Components/PageSeo';
import { trimCharsAndAddDots } from '@@Helpers/stringHelper';
import { BlogCurrentPage, BlogCustomBreadcrumbs, BlogEntryContentContainer, BlogPageContainer, BlogPageTitle, BlogPostHeading, BlogPostImageContentContainer, HeaderImageContainer, LoadingContainer, StyledCMSContentContainer, } from './styled';
import 'dayjs/locale/pl';
dayjs.locale('pl');
const BlogEntryPage = () => {
    const { urlKey } = useParams();
    const { translate } = useLang();
    const { location, goTo } = useRouter();
    const [blogEntry, setBlogEntry] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const categories = useSelector(blogCategories);
    useEffect(() => {
        const getBlogPosts = () => __awaiter(void 0, void 0, void 0, function* () {
            setIsLoading(true);
            const blogPosts = yield LSF.get(BlogService).getBlogPosts();
            const entry = blogPosts.find((item) => item.url_key === urlKey);
            if (!entry) {
                goTo('/404');
            }
            else {
                setBlogEntry(entry);
                setIsLoading(false);
            }
        });
        getBlogPosts();
        getBlogCategories();
    }, [location.pathname]);
    const getCategoryLabel = (categoryIds) => categories === null || categories === void 0 ? void 0 : categories.map((category) => {
        var _a;
        return ((categoryIds === null || categoryIds === void 0 ? void 0 : categoryIds.includes((_a = category === null || category === void 0 ? void 0 : category.id) === null || _a === void 0 ? void 0 : _a.toString())) && (category === null || category === void 0 ? void 0 : category.name)) ||
            '';
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(PageSeo, { seo: {
                title: (blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.meta_title) || (blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.name),
                description: blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.meta_description,
                keywords: blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.meta_keywords,
            } }),
        isLoading || !blogEntry ? (React.createElement(LoadingContainer, null,
            React.createElement(Spinner, { size: 40, speed: "0.5s" }))) : (React.createElement(BlogPageContainer, null,
            React.createElement(MainContainer, null,
                React.createElement(BlogCustomBreadcrumbs, null,
                    React.createElement(Breadcrumbs, null,
                        React.createElement(BreadcrumbElementWrapper, null,
                            React.createElement(Link, { to: "/aktualnosci" }, translate('pages.news'))),
                        React.createElement(BreadcrumbElementWrapper, null,
                            React.createElement(BlogCurrentPage, null,
                                React.createElement(Link, { to: `/aktualnosci/${urlKey}` }, trimCharsAndAddDots(blogEntry.name, 70)))))),
                React.createElement(HeaderImageContainer, { background: ThumbnailUtils.getImageSrc({
                        image: blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.featured_image,
                        entity: '/blog/',
                        fit: null,
                        width: 1135,
                        height: 473,
                    }) }),
                React.createElement(BlogPostImageContentContainer, null,
                    React.createElement(BlogPostHeading, null,
                        React.createElement(Link, { to: '/aktualnosci' },
                            React.createElement(Icon, { icon: "back", width: "14", height: "10" }),
                            React.createElement(Button, { appearance: "text", margin: "0 0 0 10px" }, translate('pages.back'))))),
                React.createElement(BlogEntryContentContainer, null,
                    React.createElement("p", null, dayjs(blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.created_at).format('DD/MM/YYYY')),
                    getCategoryLabel(blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.category_ids).map((element, index) => element && React.createElement("span", { key: index },
                        " ",
                        element,
                        " ")),
                    React.createElement(BlogPageTitle, null, blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.name),
                    React.createElement(StyledCMSContentContainer, null,
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.content } })))),
            React.createElement(RelatedArticles, { currentPostId: blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.id, articlesCategory: blogEntry === null || blogEntry === void 0 ? void 0 : blogEntry.category_ids[0] })))));
};
export default BlogEntryPage;
