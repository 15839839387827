import React from 'react';
import { useLang } from '@@Hooks/useLang';
import { Title, Subtitle, NameWrapper, SpecificationContainer, SpecificationWrapper, SpecificationRow, ValueWrapper, Value, Name, } from '@@Pages/ProductPage/ProductSpecification/styled';
import { ProductUtils } from '@grupakmk/libstorefront';
export const ProductSpecification = ({ product }) => {
    const { translate } = useLang();
    const attributes = ProductUtils.getProductAttributes(product);
    return (React.createElement(SpecificationWrapper, null,
        React.createElement(Title, null, product.name),
        React.createElement(Subtitle, null, translate('headers.technical_specifications')),
        React.createElement(SpecificationContainer, null, attributes.map((element, index) => {
            const [attribute] = Object.values(element);
            return (React.createElement(SpecificationRow, { key: index },
                React.createElement(NameWrapper, null,
                    React.createElement(Name, null, attribute.label)),
                React.createElement(ValueWrapper, null,
                    React.createElement(Value, null, attribute.value))));
        }))));
};
