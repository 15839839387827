import styled from 'styled-components';
export const DescriptionContainer = styled.div `
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${(props) => props.theme.mixins.mobile(`
    padding: 0;
  `)};
`;
export const Title = styled.h4 `
  font-weight: bold;
  padding-bottom: 35px;
  ${(props) => props.theme.mixins.mobile(`
    padding-bottom: 25px; 
    font-size: 20px;
  `)};
`;
export const Description = styled.div `
  position: relative;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid black;
  overflow: auto;
  padding-bottom: 45px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
  ${(props) => props.theme.mixins.mobile(`
    overflow: auto; 
    border: none;
  `)}
`;
export const DescriptionText = styled.div `
  position: relative;
  margin: 0;
  z-index: 0;
  font-size: 16px;
  line-height: 26px;
  ${(props) => props.theme.mixins.mobile(`
    font-size: 14px; 
    line-height: 23px;
  `)};
`;
