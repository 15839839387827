import styled from 'styled-components';
export const RelatedArticlesContainer = styled.div `
  width: 100%;
  text-align: center;
  margin-top: 90px;
`;
export const RelatedArticlesRow = styled.div `
  width: 100%;
  max-width: 1366px;
  display: inline-block;

  h4 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 800;
    text-align: left;
    margin-bottom: 35px;

    ${(props) => props.theme.mixins.mobile(`
      font-size: 19px;
      margin-bottom: 20px;
      padding: 0 15px;
    `)}
  }
`;
export const RelatedPostsRow = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;

  ${(props) => props.theme.mixins.mobile(`
    padding: 0 15px;
  `)}
`;
export const RelatedPostsGrid = styled.div `
  width: 100%;
  max-width: 1366px;

  display: grid;
  grid-gap: 26px;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;
export const PostContainer = styled.div `
  width: 100%;
  border: 1px solid #bababa;
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:hover {
    border-color: #000;
  }
`;
export const PostImageContainer = styled.div `
  padding-top: 56.8%;
  position: relative;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100% !important;
    object-fit: contain;
  }
`;
export const PostInnerContainer = styled.div `
  padding: 20px;
`;
export const PostHeader = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 21px;
  font-size: 14px;
  margin-bottom: 10px;

  span {
    display: inline-block;
    margin-right: 10px;
    color: #4a4747;
    text-transform: lowercase;
  }

  p {
    margin: 0;
    letter-spacing: 0.28px;
  }
`;
export const BlogPostTitle = styled.div `
  min-height: 60px;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 20px;

  ${(props) => props.theme.mixins.mobile(`
      min-height: 0;
  `)}
`;
export const BlogPostShortContent = styled.div `
  min-height: 46px;
  text-align: left;
  font-size: 14px;
  line-height: 23px;

  ${(props) => props.theme.mixins.mobile(`
    min-height: 0;
  `)}
`;
