import styled from 'styled-components';
export const LoadingContainer = styled.div `
  padding: 250px 0;
`;
export const BlogCustomBreadcrumbs = styled.div `
  ${(props) => props.theme.mixins.mobile('display: none;')}
`;
export const BlogPageContainer = styled.div `
  padding-top: 17px;
  padding-bottom: 60px;
  ${(props) => props.theme.mixins.mobile('padding-top: 0;')}
`;
export const BlogPageTitle = styled.div `
  font-size: 35px;
  line-height: 45px;
  font-weight: bold;
  margin-bottom: 40px;
`;
export const HeaderImageContainer = styled.div `
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 473px;
  ${(props) => props.theme.mixins.mobile(`
    height: 130px;
  `)}
`;
export const BlogEntryContentContainer = styled.div `
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
  font-size: 14px;

  span {
    display: inline-block;
    color: #4a4747;
    line-height: 22px;
    margin: 0 10px 10px 0;
  }

  p {
    margin-bottom: 10px;

    ${(props) => props.theme.mixins.mobile(`
      margin-top: 30px;
    `)}
  }

  ${(props) => props.theme.mixins.mobile(`
    padding: 0 15px;
  `)}
`;
export const HeaderImageContainerInner = styled.div `
  @media screen and (min-width: 751px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 38px 20px;
    z-index: 20;
    display: flex;
    align-items: flex-end;

    h1 {
      margin-bottom: 0;
    }
  }
`;
export const BlogCurrentPage = styled.div `
  color: #d9000d;
`;
export const BlogPostImageContentContainer = styled.div `
  width: 100%;
  text-align: center;

  ${(props) => props.theme.mixins.mobile(`
    padding: 0 15px;
  `)}
`;
export const BlogPostHeading = styled.div `
  width: 100%;
  position: relative;
  display: inline-block;
  max-width: 1135px;
  margin-top: 15px;
  text-align: left;

  a {
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  span {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.28px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }
`;
export const StyledCMSContentContainer = styled.div `
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.32px;

  max-width: 100%;

  p,
  div {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.32px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3 !important;
  }

  img {
    width: 100% !important;
    max-width: 100% !important;
    height: auto;
  }

  p {
    img {
      width: 100% !important;
      max-width: 100% !important;
      height: auto;
    }
  }

  a {
    font-weight: 600;
    padding-bottom: 2px;
    border-bottom: 1px solid #292220;
    text-decoration: none;
  }

  a button {
    background: #fff;
    border: 1px solid #525252;
    min-width: 250px;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    line-height: 24px;
    padding: 8px 12px;
    &:hover {
      border-color: #d9000d;
    }
    &.full {
      background: #000;
      color: #fff;
      &:hover {
        background-color: #d9000d;
      }
    }
    &:active {
      position: relative;
      top: 2px;
    }
  }
`;
