import styled from 'styled-components';
import { Button } from '@@Components/Buttons/Button';
export const ProductContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 22.3vw;
  min-height: auto;
  border: 1px solid #bababa;
  padding: 10px 10px 15px 10px;
  @media (min-width: 1341px) {
    width: 300px;
  }
  @media (max-width: 1350px) {
    width: 21vw;
  }
  @media (max-width: 1280px) {
    width: 19vw;
  }
  @media (max-width: 1100px) {
    width: 28vw;
  }
  @media (max-width: 1000px) {
    width: 30vw;
  }
  ${(props) => props.theme.mixins.mobile(`
    width: 45vw; 
    padding: 5px 5px 10px 5px;
  `)};
  @media (max-width: 550px) {
    width: 90vw;
  }
`;
export const ProductName = styled.h1 `
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 95%;
  text-overflow: ellipsis;
`;
export const TextContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-top: 15px;
  ${(props) => props.theme.mixins.mobile('padding-top: 10px;')};
`;
export const PriceContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;
`;
export const BeforePrice = styled.p `
  font-size: 16px;
  margin: 0;
  ${(props) => props.theme.mixins.mobile('font-size: 14px;')};
`;
export const Price = styled.h2 `
  font-size: 16px;
  color: #d9000d;
  padding-left: 10px;
  margin: 0;
  ${(props) => props.theme.mixins.mobile('font-size: 14px;')};
`;
export const StyledButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  line-height: 42px;
  font-weight: 600;
  font-style: italic;
  ${(props) => props.theme.mixins.mobile(`
    height: 34px; 
    font-size: 13px; 
    line-height: 34px;
  `)};
`;
