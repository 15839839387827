import React from 'react';
import { RentalsContainer, ContentContainer, Title, Subtitle, ButtonCustom, RentalsInnerContent, Description, Image, } from '@@Components/Rentals/styled';
import { ThumbnailUtils } from '@@Storefront';
export const Rentals = ({ content, subtitle, title, imageUrl, image_text_color, link, }) => {
    return (React.createElement(RentalsContainer, null,
        React.createElement(Image, { src: ThumbnailUtils.getImageSrc({
                image: imageUrl,
                entity: '',
                height: 900,
            }) }),
        React.createElement(RentalsInnerContent, null,
            React.createElement(ContentContainer, { color: image_text_color || '#fff' },
                React.createElement("div", null,
                    subtitle && React.createElement(Subtitle, null, subtitle),
                    title && React.createElement(Title, null, title),
                    content && (React.createElement(Description, { dangerouslySetInnerHTML: { __html: content } }))),
                React.createElement("div", null, (link === null || link === void 0 ? void 0 : link.url) && (link === null || link === void 0 ? void 0 : link.label) && (React.createElement("a", { href: link.url, target: (link === null || link === void 0 ? void 0 : link.target) || '_self', rel: "noopener noreferrer" },
                    React.createElement(ButtonCustom, { color: "black", maxWidth: "250" },
                        React.createElement("i", null, link.label)))))))));
};
