import styled from 'styled-components';
export const ItemContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Item = styled.img `
  height: 80px;
  ${(props) => props.theme.mixins.mobile('height: 70px;')}
  @media (max-width: 700px) {
    height: 60px;
  }
  @media (max-width: 600px) {
    height: 70px;
  }
  @media (max-width: 450px) {
    height: 60px;
  }
`;
export const LogosGridContainer = styled.div `
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 25px;
  grid-column-gap: 4vw;
  @media (min-width: 901px) {
    &:hover {
      > * {
        transition: 0.3s;
        opacity: 0.25;
        &:hover {
          transform: scale(1.1);
          opacity: 1;
          transition: 0.3s;
          cursor: pointer;
        }
      }
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
    > :last-child {
      grid-column-start: 2;
    }
  }
`;
