import styled from 'styled-components';
export const LocationsContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  ${(props) => props.theme.mixins.mobile('padding: 0 0;')}
`;
export const LocationContentContainer = styled.div `
  display: inline-block;
  max-width: 1135px;
  width: 100%;
  text-align: left;
`;
export const ItemsRow = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${(props) => props.theme.mixins.mobile('display: block;')}
`;
export const LocationTitle = styled.h2 `
  line-height: 24px;
  margin-bottom: 40px;
  ${(props) => props.theme.mixins.mobile('margin-bottom: 30px;')}
`;
export const LocationContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  ${(props) => props.theme.mixins.mobile(`
    margin-top: 35px; 
    padding: 0;
  `)}
`;
export const LocationContentTitle = styled.p `
  color: #000;
  font-weight: bold;
  margin-bottom: 0.8em;
  font-size: 1.42rem;
  line-height: 2.14rem;
`;
export const LocationAddress = styled.p `
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.28px;
  font-weight: 600;
  margin-bottom: 1.4em;
`;
export const LocationContactData = styled.a `
  font-size: 14px;
  letter-spacing: 0.28px;
  line-height: 22px;
  margin: 0;
  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;
export const LocationContactDataPhone = styled.a `
  font-size: 14px;
  letter-spacing: 0.28px;
  line-height: 22px;
  text-decoration: underline;
  margin-bottom: 40px;
  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
  ${(props) => props.theme.mixins.mobile('margin-bottom: 30px;')}
`;
export const ContactButton = styled.button `
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.28px;
  padding: 0;

  a {
    display: flex;
    align-items: center;
    button {
      font-weight: medium !important;
    }
  }

  p {
    margin: 0 10px 0 0;
  }
`;
