export var BlockType;
(function (BlockType) {
    BlockType["Banner"] = "1";
    BlockType["ImageText"] = "3";
    BlockType["TextImage"] = "4";
    BlockType["Pictograms"] = "999";
})(BlockType || (BlockType = {}));
export var SliderType;
(function (SliderType) {
    SliderType["TwoPictures"] = "2-images";
    SliderType["ThreePictures"] = "middle";
    SliderType["BannerSlider"] = "banner";
})(SliderType || (SliderType = {}));
