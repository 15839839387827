import styled from 'styled-components';
export const CategoryNavigationBarWrapper = styled.div `
  width: 100vw;
  background-color: #fff;
  height: 50px;
  padding: 0 36px;
  border-top: 1px solid ${(props) => props.theme.colors.dark};
  box-shadow: 0 5px 20px #00000008;
  position: sticky;
  top: 50px;
  z-index: 10;
  @media screen and (max-width: 1000px) {
    top: 0;
    z-index: 50;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  }
`;
export const CategoryNavigationInnerContainer = styled.div `
  display: flex;
  align-items: center;
  height: 50px;
  max-width: 1440px;
  margin: 0 auto;
  ${(props) => props.theme.mixins.mobile(`
        justify-content: center;
    `)}
`;
export const CategoryLogo = styled.div `
  background-image: url(${(props) => props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 90px;
  min-width: 90px;
  height: 50px;
`;
export const CategoryNavigation = styled.div `
  display: flex;
  align-items: center;
  padding-left: 60px;
  ${(props) => props.theme.mixins.mobile(`
        display: none;
    `)}
`;
export const CategoryNavigationLink = styled.div `
  margin-right: 30px;
  ${(props) => props.active &&
    `
        font-weight: 800;
    `}
`;
