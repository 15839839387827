import styled from 'styled-components';
export const MediaGalleryBannerWrapper = styled.div `
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 350px;
  display: flex;
  align-items: center;
  padding: 0 150px;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
  margin: ${(props) => (props.noMargin ? '0' : '45px 0')};

  ${(props) => props.theme.mixins.mobile(`
    width: 100%;
    padding: 0 15px;
  `)}
`;
export const MediaGalleryBannerHeader = styled.h1 `
  color: ${(props) => props.color || '#FFF'};
  white-space: pre-wrap;
`;
