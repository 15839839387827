import React from 'react';
import { useRouter } from '@@Hooks/useRouter';
import { useCategory } from '@@Hooks/useCategory';
import { useLang } from '@@Hooks/useLang';
import { useDevice } from '@@Hooks/useDevice';
import { CategoryLogo, CategoryNavigation, CategoryNavigationBarWrapper, CategoryNavigationInnerContainer, CategoryNavigationLink, } from '@@Components/CategoryNavigationBar/styled';
import { ThumbnailUtils } from '@grupakmk/libstorefront';
import { Link } from 'react-router-dom';
import StickyElementWatcher from 'react-sticky-element-watcher';
export const CategoryNavigationBar = () => {
    var _a, _b;
    const { location } = useRouter();
    const path = (_a = location === null || location === void 0 ? void 0 : location.pathname) === null || _a === void 0 ? void 0 : _a.split('/');
    const { currentCategory } = useCategory((path === null || path === void 0 ? void 0 : path.length) > 0 ? `motocykle/${path[path.length - 1]}` : '');
    const { translate } = useLang();
    useDevice((device) => {
        try {
            document.getElementById('layout-container').style.paddingTop =
                device === 'mobile' ? '40px' : 'unset';
        }
        catch (e) { }
    });
    const isLinkActive = (linkType) => {
        switch (linkType) {
            case 'products': {
                return location.pathname.indexOf(currentCategory.url_path) > -1;
            }
            case 'brand': {
                return location.pathname === `/${currentCategory.url_key}`;
            }
            case 'test_drive': {
                return (location.pathname.indexOf(`jazda-probna/${currentCategory.url_key}`) >
                    -1);
            }
            default:
                return false;
        }
    };
    const isSinglePathSlug = ((_b = path === null || path === void 0 ? void 0 : path.filter((item) => !!item)) === null || _b === void 0 ? void 0 : _b.length) === 1;
    if (!isSinglePathSlug || !currentCategory)
        return null;
    return (React.createElement(StickyElementWatcher, { render: (isStuck) => (React.createElement(CategoryNavigationBarWrapper, { isStuck: isStuck },
            React.createElement(CategoryNavigationInnerContainer, null,
                React.createElement(CategoryLogo, { background: ThumbnailUtils.getImageSrc({
                        image: currentCategory.brand_logo,
                        width: 200,
                        height: 100,
                        entity: '',
                    }) }),
                React.createElement(CategoryNavigation, null,
                    React.createElement(CategoryNavigationLink, { active: isLinkActive('products') },
                        React.createElement(Link, { to: `/${currentCategory.url_path}` }, translate('nav.category_products'))),
                    React.createElement(CategoryNavigationLink, { active: isLinkActive('brand') },
                        React.createElement(Link, { to: `/${currentCategory.url_key}` }, translate('nav.category_details'))),
                    React.createElement(CategoryNavigationLink, { active: isLinkActive('test_drive') },
                        React.createElement(Link, { to: `/jazda-probna/${currentCategory.url_key}` }, translate('nav.category_test_drive'))))))) }));
};
