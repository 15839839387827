import styled from 'styled-components';
export const EmployeeContainer = styled.div `
  width: 300px;
  height: 350px;
  display: flex;
  flex-direction: column;
  @media (max-width: 450px) {
    width: 290px;
  }
  @media (max-width: 319px) {
    width: 270px;
  }
`;
export const EmployeeImage = styled.div `
  background-image: url('${(props) => props.url}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 246px;
  width: 300px;
  @media (max-width: 450px) {
    width: 290px;
  }
  @media (max-width: 319px) {
    width: 270px;
  }
`;
export const Title = styled.p `
  font-weight: bold;
  margin: 20px 0 10px 0;
`;
export const PhoneMailLink = styled.a `
  margin: 0;
  ${(props) => props.underline && 'text-decoration: underline;'}
  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;
