import React, { useEffect, useState } from 'react';
import { useLang } from '@@Hooks/useLang';
import { useSelector } from 'react-redux';
import { mageConfigSelector } from '@@Redux/selectors/config.selectors';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { IconDefs } from '@@Components/Icon/IconDefs';
import { Notifications } from '@@Components/Notifications';
import { Header } from '@@Components/Header';
import { Footer } from '@@Components/Footer';
import { dispatch } from '@@Redux/dispatch';
import { setIsMinicartOpened } from '@@Redux/actions/minicart.actions';
import { usePageScroll } from '@@Hooks/usePageScroll';
const ScrollContainer = styled.div `
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;
const PageContainer = styled.div `
  position: relative;
`;
const MainContainer = styled.div `
  position: relative;
  width: 100%;
  @media (min-width: 883px) {
    margin-top: 0;
  }
  min-height: calc(100vh - 327px);
`;
export const Layout = (props) => {
    const { language } = useLang();
    const [title, setTitle] = useState('');
    const mageConfig = useSelector(mageConfigSelector);
    usePageScroll(() => dispatch(setIsMinicartOpened(false)));
    useEffect(() => { var _a; return ((_a = mageConfig.seo) === null || _a === void 0 ? void 0 : _a.metaTitle) && setTitle(mageConfig.seo.metaTitle); }, [mageConfig]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("html", { lang: language.slice(0, 2) }),
            React.createElement("title", null, title),
            React.createElement("meta", { name: "viewport", content: "width=device-width, initial-scale=1.0" })),
        React.createElement(IconDefs, null),
        React.createElement(ScrollContainer, { id: "layout-container" },
            React.createElement(PageContainer, null,
                React.createElement(Header, null),
                React.createElement(MainContainer, null, props.children),
                React.createElement(Notifications, null),
                React.createElement(Footer, null)))));
};
