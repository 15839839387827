import styled from 'styled-components';
export const CorporateLinksWrapper = styled.div `
  display: flex;
  width: 100%;
  color: ${(props) => props.color};

  ${(props) => props.theme.mixins.mobile(`
    flex-flow: row wrap;
  `)}
`;
export const CorporateLink = styled.a `
  color: ${(props) => props.color};
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 1.8rem 2.5rem 1.425rem;
  flex-grow: 2;
  margin: 0 0;
  min-height: 200px;
  display: flex;
  flex-flow: column;

  svg {
    fill: ${(props) => props.color} !important;
  }

  &:nth-child(even) {
    margin-right: 5px;
    margin-left: 5px;
  }
  ${(props) => props.theme.mixins.mobile(`
    width: 100%;
    margin: 5px 0;
    padding: 2rem;
    &:nth-child(even) {
      margin: 0;
    }
  `)}
  @media screen and (max-width: 550px) {
    min-height: 144px;
    padding: 20px;
  }
`;
export const CorporateLinkTitle = styled.h2 `
  white-space: pre-wrap;
  flex-grow: 2;
  ${(props) => props.theme.mixins.mobile(`
    white-space: normal;
  `)}
`;
export const CorporateRedirectButton = styled.a `
  svg {
    fill: #fff;
    margin-left: 1rem;
    display: inline;
  }
  &:hover {
    text-decoration: underline;
  }
`;
export const CorporateLinkWrapper = styled.div `
  svg {
    fill: #fff;
    margin-left: 1rem;
    display: inline;
  }
  &:hover {
    text-decoration: underline;
  }
`;
