import styled from 'styled-components';
export const CategoriesContainer = styled.div `
  display: flex;
  flex-direction: column;
  max-width: 360px;
  ${(props) => props.theme.mixins.mobile('padding-top: 10px;')};
  position: sticky;
  top: 120px;
  @media (max-width: 1000px) {
    top: 80px;
  }
  ${(props) => props.theme.mixins.mobile('position: static; top: 0;')};
`;
export const CategoriesListContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const Title = styled.h4 `
  ${(props) => props.theme.mixins.mobile('font-size: 16px;')};
  padding-bottom: 20px;
  display: ${(props) => (props.isVisible ? 'inline;' : 'none')};
`;
export const Subtitle = styled.p `
  font-weight: bold;
  padding-bottom: 15px;
  margin: 0;
`;
export const CategoryLabel = styled.a `
  margin-bottom: 10px;
  width: max-content;
  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
  ${(props) => props.theme.mixins.mobile(`
    &:hover, &:active {
      color: black;
    }
  `)};
`;
