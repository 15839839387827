import styled from 'styled-components';
export const LimitedContainer = styled.div `
  --max-container-width: ${(props) => (props.wide ? '1440px' : '1140px')};
  --side-padding: 15px;

  @media screen and (min-width: 480px) {
    --side-padding: ${(props) => (props.nopadding ? '0px' : '15px')};
  }

  @media screen and (min-width: 768px) {
    --side-padding: ${(props) => (props.nopadding ? '0px' : '15px')};
  }

  ${(props) => props.maxcontentwidth
    ? `max-width: ${props.maxcontentwidth}`
    : 'max-width: calc(2 * var(--side-padding) + var(--max-container-width));'};

  ${(props) => props.theme.mixins.mobile(`
    max-width: 100%;
  `)}

  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: ${(props) => props.vpadding || '0'} var(--side-padding);
  display: ${(props) => (props.flex ? 'flex' : 'block')};
`;
