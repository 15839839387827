import { __awaiter } from "tslib";
import { getBlogCategories } from '@@App/src/lib/storefront/actions/blog';
import { blogCategories } from '@@App/src/lib/storefront/selectors/blog';
import { BlogService } from '@grupakmk/libstorefront-blog-plugin';
import { ThumbnailUtils } from '@grupakmk/libstorefront';
import React, { useEffect, useState } from 'react';
import { useLang } from '@@Hooks/useLang';
import { useSelector } from 'react-redux';
import { Link } from '@@Components/Link';
import LSF from '@@Storefront/index';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import { PostHeader, PostContainer, BlogPostTitle, RelatedPostsRow, RelatedPostsGrid, PostInnerContainer, PostImageContainer, RelatedArticlesRow, BlogPostShortContent, RelatedArticlesContainer, } from './styled';
dayjs.locale('pl');
export const RelatedArticles = (props) => {
    const [blogPosts, setBlogPosts] = useState([]);
    const categories = useSelector(blogCategories);
    const { translate } = useLang();
    useEffect(() => {
        const getBlogPosts = () => __awaiter(void 0, void 0, void 0, function* () {
            const blogPosts = yield LSF.get(BlogService).getBlogPosts();
            /**
             * If there are articles in the same category, please return them,
             * otherwise return any articles
             */
            setBlogPosts(blogPosts
                .filter((entry) => {
                if (props === null || props === void 0 ? void 0 : props.articlesCategory) {
                    return (entry.type === 'post' &&
                        entry.category_ids.includes(props.articlesCategory) &&
                        entry.id != props.currentPostId);
                }
                return entry.type === 'post';
            })
                .reverse());
        });
        getBlogCategories();
        getBlogPosts();
    }, []);
    /**
     * load categories data
     */
    useEffect(() => { }, [categories]);
    /**
     * load category data based on id
     */
    const getCategoryLabel = (categoryIds) => {
        const categorieNames = categories.map((category) => {
            if (categoryIds.includes(category.id.toString())) {
                return category.name;
            }
        });
        return categorieNames;
    };
    return blogPosts.length > 0 ? (React.createElement(RelatedArticlesContainer, null,
        React.createElement(RelatedArticlesRow, null,
            React.createElement("h4", null, translate('pages.may_interest_you'))),
        React.createElement(RelatedPostsRow, null,
            React.createElement(RelatedPostsGrid, null, blogPosts.slice(0, 3).map((entry, index) => {
                return (
                // Single post template
                React.createElement(Link, { key: index, to: `/aktualnosci/${entry.url_key}` },
                    React.createElement(PostContainer, null,
                        React.createElement(PostImageContainer, null,
                            React.createElement("img", { alt: (entry === null || entry === void 0 ? void 0 : entry.name) || undefined, src: ThumbnailUtils.getImageSrc({
                                    image: entry.featured_image,
                                    width: 439,
                                    height: 249,
                                    fit: '',
                                    entity: '/blog/',
                                }) })),
                        React.createElement(React.Fragment, null,
                            React.createElement(PostInnerContainer, null,
                                React.createElement(PostHeader, null,
                                    React.createElement("div", null, getCategoryLabel(entry.category_ids).map((element, index) => {
                                        if (element) {
                                            return React.createElement("span", { key: index },
                                                " ",
                                                element,
                                                " ");
                                        }
                                    })),
                                    React.createElement("p", null, dayjs(entry.created_at).format('DD/MM/YYYY'))),
                                React.createElement(BlogPostTitle, null, entry.name.length > 16
                                    ? entry.name.slice(0, 62) + '...'
                                    : entry.name),
                                React.createElement(BlogPostShortContent, null, entry.short_content))))));
            }))))) : null;
};
