import styled from 'styled-components';
export const ProductsContainer = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const GridContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 25px;
  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 0;
  }
  ${(props) => props.theme.mixins.mobile(`
    grid-template-columns: repeat(2, 1fr);
  `)}
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;
export const GridItem = styled.a `
  display: flex;
  cursor: pointer;
`;
