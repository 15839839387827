import styled from 'styled-components';
export const EmployeesTabContainer = styled.div `
  width: 100%;
`;
export const TabContentContainer = styled.div `
  margin: 0 115px;
  @media (max-width: 1250px) {
    margin: 0 75px;
  }
  ${(props) => props.theme.mixins.mobile('margin: 0 25px;')}
  @media (max-width: 700px) {
    margin: 0;
  }
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const GridContainerWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const GridContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 118px;
  row-gap: 45px;
  align-self: flex-start;
  @media (max-width: 1439px) {
    column-gap: 6vw;
  }
  @media (max-width: 1350px) {
    column-gap: 3vw;
  }
  @media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 10vw;
  }
  ${(props) => props.theme.mixins.mobile(`
    column-gap: 4vw;
    row-gap: 30px;
  `)}
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 450px) {
    column-gap: 0;
  }
`;
export const TabItemContainer = styled.div `
  display: flex;
  justify-content: center;
  @media (max-width: 450px) {
    width: 100%;
  }
`;
export const BrandLogoWrapper = styled.div `
  @media (max-width: 450px) {
    width: 290px;
    display: flex;
    justify-content: flex-start;
  }
`;
export const BrandLogo = styled.div `
  background-image: url('${(props) => props.src}');
  height: 70px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  width: auto;
  max-width: 300px;

  ${(props) => props.theme.mixins.mobile(`
    height: 50px;
    width: 150px;
  `)}
`;
export const JobName = styled.h4 `
  margin: 45px 0 20px 0;
  align-self: flex-start;
  @media (max-width: 450px) {
    max-width: 290px;
  }
`;
export const NoEmployeesPlaceholder = styled.div `
  display: flex;
  justify-content: center;
`;
