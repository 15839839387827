import React from 'react';
import { LogosGridContainer, Item, ItemContainer, } from '@@Components/LogosGrid/styled';
export const LogosGrid = ({ items = [
    {
        image: './assets/logos/husqvarna.svg',
        link: '/husqvarna',
        title: 'Husqvarna',
        width: 85,
        height: 80,
    },
    {
        image: './assets/logos/harley_davidson.svg',
        link: 'https://harley-davidson-lodz.pl/',
        title: 'Harley Davidson',
        width: 86,
        height: 80,
    },
    {
        image: './assets/logos/bmw.svg',
        link: '/nasze-bmw',
        title: 'Bmw',
        width: 64,
        height: 80,
    },
    {
        image: './assets/logos/piaggio.svg',
        link: '/piaggio',
        title: 'Piaggio',
        width: 83,
        height: 80,
    },
    {
        image: './assets/logos/ktm.svg',
        link: '/ktm',
        title: 'Ktm',
        width: 81,
        height: 80,
    },
    {
        image: './assets/logos/ducati.svg',
        link: '/nasze-ducati',
        title: 'Ducati',
        width: 114,
        height: 80,
    },
    {
        image: './assets/logos/aprilia.svg',
        link: '/aprilia',
        title: 'Aprilia',
        width: 86,
        height: 80,
    },
    {
        image: './assets/logos/yamaha.svg',
        link: 'https://libertymotors.yamaha-motor.pl/',
        title: 'Yamaha',
        width: 98,
        height: 80,
    },
    {
        image: './assets/logos/vespa.svg',
        link: '/vespa',
        title: 'Vespa',
        width: 99,
        height: 80,
    },
    {
        image: './assets/logos/moto_guzzi.svg',
        link: '/moto-guzzi',
        title: 'Moto Guzzi',
        width: 132,
        height: 80,
    },
], }) => {
    if (!(items instanceof Array) || !items.length)
        return null;
    return (React.createElement(LogosGridContainer, null, items.map((element, index) => (React.createElement(ItemContainer, { key: index, id: `logo${index}` },
        React.createElement("a", { href: element.link, target: element.link.substring(0, 4) === 'http' ? '_blank' : '_self', title: element.title, rel: "noreferrer" },
            React.createElement(Item, { src: element.image, alt: element.title, title: element.title, loading: "lazy", width: element.width, height: element.height })))))));
};
