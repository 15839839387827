import styled from 'styled-components';
export const SpecificationWrapper = styled.div ``;
export const Title = styled.h4 `
  padding-bottom: 35px;
  ${(props) => props.theme.mixins.mobile(`
    font-size: 20px; 
    padding-bottom: 25px;
  `)}
`;
export const Subtitle = styled.h5 `
  padding-bottom: 15px;
  ${(props) => props.theme.mixins.mobile('font-size: 16px;')}
`;
export const SpecificationContainer = styled.div `
  padding-bottom: 45px;
  ${(props) => props.theme.mixins.mobile('padding-bottom: 10px;')}
`;
export const SpecificationRow = styled.div `
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.input.borderColors.default};
  padding-bottom: 10px;
  padding-top: 10px;
  ${(props) => props.theme.mixins.mobile('flex-direction: column;')}
`;
export const NameWrapper = styled.div `
  width: 40%;
  ${(props) => props.theme.mixins.mobile(`
    width: 100%; 
    padding-bottom: 10px;
  `)}
`;
export const ValueWrapper = styled.div `
  width: 60%;
  ${(props) => props.theme.mixins.mobile('width: 100%;')}
`;
export const Name = styled.p `
  margin: 0;
  text-align: left;
`;
export const Value = styled(Name) `
  font-weight: 600;
`;
