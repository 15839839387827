import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { MediaGalleryBannerHeader, MediaGalleryBannerWrapper, } from '@@Components/MediaGalleryBanner/styled';
import { getMediaByPath } from '@@Storefront/actions/media';
import { ThumbnailUtils } from '@@Storefront';
import { LimitedContainer } from '@@Components/LimitedContainer';
export const MediaGalleryBanner = (props) => {
    const [item, setItem] = useState();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!props.path)
                return;
            const [media] = yield getMediaByPath(props.path, true);
            setItem(media);
        }))();
    }, []);
    if (!item)
        return null;
    return (React.createElement(MediaGalleryBannerWrapper, { background: ThumbnailUtils.getImageSrc({
            image: item.path,
            width: props.width,
            height: props.height,
            entity: '',
        }), width: props.width, height: props.height, noMargin: props.noMargin },
        React.createElement(LimitedContainer, { wide: true },
            React.createElement(MediaGalleryBannerHeader, null, item.description))));
};
