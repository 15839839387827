import styled from 'styled-components';
import CommonButton from '@@Components/Buttons/Button';
export const BlogPageContainer = styled.div `
  padding-top: 17px;
  padding-bottom: 60px;

  ${(props) => props.theme.mixins.mobile(`
    padding-bottom: 40px;
  `)};
`;
export const BlogPostContainer = styled.div `
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const BlogEntriesGrid = styled.div `
  display: grid;
  grid-gap: 26px;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;

  @media screen and (max-width: 850px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;
export const BlogEntryInner = styled.div `
  height: 265px;
  padding: 20px;
  border: 1px solid #bababa;

  &:hover {
    border-color: #000;
  }

  @media screen and (max-width: 850px) {
    padding: 15px;
  }
`;
export const BlogEntryHeader = styled.div `
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  color: #4a4747;
  padding-bottom: 15px;

  p {
    letter-spacing: 0.28px;
    margin: 0;
    padding-left: 5px;
  }
`;
export const BlogEntryImageContainer = styled.div `
  padding-top: 56.9%;
  position: relative;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100% !important;
    object-fit: contain;
  }
`;
export const BlogSortRow = styled.div `
  padding: 25px 0;
  width: 100%;
  display: flex;

  p {
    font-size: 14px;
    line-height: 21px;
  }
`;
export const BlogPostTitle = styled.div `
  min-height: 60px;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) => props.theme.mixins.mobile(`
    min-height: 0;
  `)}
`;
export const BlogPostShortContent = styled.div `
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) => props.theme.mixins.mobile(`
    min-height: 0;
  `)}
`;
export const LoadingContainer = styled.div `
  padding: 250px 0;
`;
export const BlogButtonsContainer = styled.div `
  width: 100%;
  text-align: center;
`;
export const ShowMoreButton = styled(CommonButton) `
  max-width: 210px;
  margin-top: 20px;
  font-size: 14px;
  padding: 0;
  border: 1px solid black;
  background-color: #ffffff;
  color: black;

  &:hover,
  &:active {
    border-color: #d9000d;
  }

  ${(props) => props.theme.mixins.mobile(`
    margin-top: 40px;
    font-size: 13px; 
    width: 100%;
    max-width: 100%;
    height: 34px; 
    display: flex;
    align-items: center;
    justify-content: center;
  `)};
`;
export const CategoriesWrapper = styled.div `
  display: inline-flex;
  flex-wrap: wrap;
  column-gap: 10px;
  text-transform: lowercase;
`;
