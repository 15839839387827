import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLang } from '@@Hooks/useLang';
import { Icon } from '@@Components/Icon/Icon';
import { blogCategories } from '@@Storefront/selectors/blog';
import { FilterItem, FiltersContainer } from './styled';
export const BlogFilterBar = (props) => {
    var _a;
    const { translate } = useLang();
    const categories = useSelector(blogCategories);
    /**
     * load category data based on id
     */
    const getCategoryLabel = (categoryIds) => {
        const categorieNames = categories.map((category) => {
            if (categoryIds.includes(category.id.toString())) {
                return category.name;
            }
        });
        return categorieNames;
    };
    /**
     * load category data based on id
     */
    const getCategoryUrlKey = (categoryId) => {
        const category = categories.find((category) => {
            if (categoryId === category.id.toString()) {
                return category;
            }
        });
        return category === null || category === void 0 ? void 0 : category.url_key;
    };
    return (React.createElement(FiltersContainer, null,
        React.createElement(Link, { to: "/aktualnosci" },
            React.createElement(FilterItem, { isActive: !props.currentCategoryId },
                React.createElement(Icon, { icon: 'active', width: "20", height: "20" }),
                React.createElement("span", null, translate('pages.all')))),
        ((_a = props === null || props === void 0 ? void 0 : props.categories) === null || _a === void 0 ? void 0 : _a.length) ? (React.createElement(React.Fragment, null, props.categories.map((postId, index) => (React.createElement(Link, { key: index, to: '/aktualnosci/' + getCategoryUrlKey(postId) },
            React.createElement(FilterItem, { isActive: props.currentCategoryId && postId === props.currentCategoryId, key: index },
                React.createElement(Icon, { icon: 'active', width: "20", height: "20" }),
                getCategoryLabel([postId]).map((element, index) => {
                    if (element) {
                        return React.createElement("span", { key: index },
                            " ",
                            element,
                            " ");
                    }
                }))))))) : null));
};
