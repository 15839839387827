import styled from 'styled-components';
export const DrawerWrapper = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 100;
  padding: ${(props) => props.padding};
  overflow-x: auto;
`;
export const CloseDrawerWrapper = styled.div `
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
`;
