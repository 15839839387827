import styled from 'styled-components';
export const FiltersContainer = styled.ul `
  width: 100%;
  padding: 25px 0;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;

  ${(props) => props.theme.mixins.mobile(`
    max-width: 100%;
    overflow-x: scroll;
    padding-right: 10px;
  `)}
`;
export const FilterItem = styled.li `
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  font-size: 16px;
  line-height: 26px;

  svg {
    display: none !important;
  }

  span {
    white-space: nowrap;
  }

  ${(props) => props.isActive
    ? `
    font-size: 20px;
    font-weight: 900;

    svg {
      display: block !important;
      margin-right: 10px;
    }
  `
    : ''}
`;
