import styled from 'styled-components';
import { Button } from '@@Components/Buttons/Button';
export const ProductBannerContainer = styled.div `
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f2f2f2;
  height: 450px;
  display: flex;
  align-items: center;
`;
export const BannerTextContainer = styled.div `
  color: ${(props) => props.color};
`;
export const BannerBrand = styled.h5 `
  font-weight: normal;
  padding-bottom: 15px;
  ${(props) => props.theme.mixins.mobile('padding-bottom: 5px;')};
`;
export const BannerModel = styled.h1 `
  font-size: 60px;
  line-height: 44px;
  letter-spacing: 1.8px;
  ${(props) => props.theme.mixins.mobile('font-size: 28px; line-height: 34px;')};
`;
export const PriceWrapper = styled.div `
  padding: 55px 0 35px 0;
  ${(props) => props.theme.mixins.mobile('padding: 50px 0 0 0;')};
`;
export const PriceFromLabel = styled.h5 `
  display: inline;
  font-weight: normal;
  padding-right: 10px;
  ${(props) => props.theme.mixins.mobile('font-size: 13px;')};
`;
export const Price = styled.h1 `
  display: inline;
  line-height: 44px;
  font-size: 40px;
  ${(props) => props.theme.mixins.mobile('font-size: 24px; line-height: 23px;')};
`;
export const BannerButtonsContainer = styled.div `
  display: flex;
  ${(props) => props.theme.mixins.mobile('display: none')};
`;
export const StyledButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 42px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  ${(props) => props.margin && `margin: ${props.margin}`};
  ${(props) => props.theme.mixins.mobile(`
    font-size: 13px; 
    width: 290px;
    border: 1px solid;
    ${(props) => props.margin && `margin: ${props.margin}`};
  `)};
`;
export const MobileButtonsContainer = styled.div `
  display: none;
  ${(props) => props.theme.mixins.mobile(`
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
  `)};
`;
