import React, { useRef, useEffect } from 'react';
import StaticPage from '@@Pages/StaticPage';
import { useRouter } from '@@Hooks/useRouter';
import { useParams } from 'react-router';
import { DynamicForm } from '@@Forms/dynamic-form';
import { AskAboutAvailabilityPageWrapper, AskAboutAvailabilityFormWrapper, } from '@@Pages/AskAboutProductPage/styled';
const AskAboutProductPage = () => {
    const ref = useRef();
    const { model } = useParams();
    const { goTo } = useRouter();
    useEffect(() => {
        if (model) {
            ref.current.fill({ '5': model });
        }
    }, [ref]);
    return (React.createElement(AskAboutAvailabilityPageWrapper, null,
        React.createElement(StaticPage, { path: "/dostepnosc" }),
        React.createElement(AskAboutAvailabilityFormWrapper, null,
            React.createElement(DynamicForm, { ref: ref, code: "ask_about_availability", onSubmit: () => goTo('/form/submit') }))));
};
export default AskAboutProductPage;
