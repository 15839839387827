import React, { useEffect, useRef } from 'react';
import StaticPage from '@@Pages/StaticPage';
import { TestDriveFormWrapper, TestDriveWrapper, } from '@@Pages/TestDrivePage/styled';
import { DynamicForm } from '@@Forms/dynamic-form';
import { useParams } from 'react-router';
import { useRouter } from '@@Hooks/useRouter';
const TestDrivePage = () => {
    const ref = useRef();
    const { model } = useParams();
    const { goTo } = useRouter();
    useEffect(() => model && ref.current.fill({ '12': model }), [ref]);
    return (React.createElement(TestDriveWrapper, null,
        React.createElement(StaticPage, { path: "jazda-probna", firstLineWords: 2 }),
        React.createElement(TestDriveFormWrapper, null,
            React.createElement(DynamicForm, { ref: ref, code: "test_drive", onSubmit: () => goTo('/form/submit') }))));
};
export default TestDrivePage;
