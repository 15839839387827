import styled from 'styled-components';
export const BottomImagesContainer = styled.div `
  display: flex;
  width: 100%;
  padding: 5px 0 30px 0;
  ${(props) => props.theme.mixins.mobile(`
    padding: 5px 0 0 0;
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
  `)};
`;
export const ProductDesignContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;
