import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { ProductsContainer, GridContainer, GridItem, } from '@@Components/SelectedProducts/styled';
import { ProductTile } from '@@Components/ProductTile/';
import LSF from '@@Storefront';
export const SelectedProducts = ({ items, onClick = () => { }, }) => {
    if (!items || !(items instanceof Array) || !items.length)
        return null;
    const [productsWithPrices, setProductsWithPrices] = useState();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const priceMissingIds = items
                .map((item) => !item.prices && item.id)
                .filter((item) => typeof item === 'number');
            const prices = yield LSF.ProductService.getProductPrices(priceMissingIds);
            setProductsWithPrices(items.map((item) => (Object.assign(Object.assign({}, item), { prices: prices.find((price) => price.id === item.id) }))));
        }))();
    }, [items]);
    return (React.createElement(ProductsContainer, null,
        React.createElement(GridContainer, null, productsWithPrices === null || productsWithPrices === void 0 ? void 0 : productsWithPrices.map((element, index) => (React.createElement(GridItem, { key: index, onClick: () => onClick(element.id) },
            React.createElement(ProductTile, { product: element })))))));
};
