import React from 'react';
import styled from 'styled-components';
import { Icon } from '@@Components/Icon/Icon';
const SocialButtonsContainer = styled.ul `
  width: 100%;
  margin-top: 2em;
  ${(props) => props.theme.mixins.mobile('margin-bottom: 2em;')}
  li {
    display: inline-block;
    margin-right: 15px;
  }
  svg {
    fill: #fff;
  }
`;
export const SocialButtons = () => {
    return (React.createElement(SocialButtonsContainer, null,
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.facebook.com/Liberty-Motors-Piaseczno-312456798798209", title: "facebook" },
                React.createElement(Icon, { icon: "facebook", width: "12", height: "22" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.youtube.com/channel/UCAVZqSkP-VFeNQtFh3LDqgw", title: "youtube" },
                React.createElement(Icon, { icon: "youtube", width: "28", height: "20" }))),
        React.createElement("li", null,
            React.createElement("a", { href: "https://www.instagram.com/libertymotors.pl/", title: "instagram" },
                React.createElement(Icon, { icon: "instagram", width: "20", height: "20" })))));
};
