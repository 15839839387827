import styled from 'styled-components';
export const AskAboutAvailabilityPageWrapper = styled.div ``;
export const AskAboutAvailabilityFormWrapper = styled.div `
  max-width: 670px;
  margin: auto;
  padding: 30px 0;
  ${(props) => props.theme.mixins.mobile(`
    padding: 30px 15px;
  `)}
`;
