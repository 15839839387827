import styled from 'styled-components';
export const DamageLiquidationPageWrapper = styled.div ``;
export const StaticPageFormWrapper = styled.div `
  max-width: 670px;
  margin: 0 auto;
`;
export const LocationsWrapper = styled.div `
  padding-bottom: 4rem;

  ${(props) => props.theme.mixins.mobile(`
    padding: 0 15px;
  `)}
`;
