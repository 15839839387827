import styled from 'styled-components';
import CommonButton from '@@Components/Buttons/Button';
export const Subtitle = styled.p `
  font-weight: normal;
  margin: 0;
  padding-bottom: 15px;
  font-size: 1.14rem;
  line-height: 1.85rem;
`;
export const Title = styled.h3 `
  ${(props) => props.theme.mixins.mobile('font-size: 20px; line-height: 28px;')};
`;
export const Description = styled.div `
  padding-top: 30px;
  overflow: hidden;
  height: 150px;
  ${(props) => props.theme.mixins.mobile('height: 200px;')};
  font-size: 16px;
  font-weight: 500;
  ul li {
    margin-bottom: 20px;
    &:hover {
      font-weight: 600;
    }
  }
  ${(props) => props.theme.mixins.mobile(`
    padding: 20px 0;
    font-size: 14px;
    ul li {
      margin-bottom: 10px;
    }
  `)}
`;
export const RentalsContainer = styled.section `
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const Image = styled.img `
  object-fit: cover;
  max-height: 450px;
  min-height: 330px;
  width: 100%;
  height: 100%;
`;
export const RentalsInnerContent = styled.div `
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.theme.pages.homePage.maxWidth};
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 50px 0;
  @media (max-width: 1200px) {
    padding-left: 50px;
  }
  ${(props) => props.theme.mixins.mobile('padding: 20px 0 20px 15px')};
`;
export const ContentContainer = styled.section `
  width: 262px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${(props) => props.color};
  ${(props) => props.theme.mixins.mobile('width: 218px;')};
`;
export const ButtonCustom = styled(CommonButton) `
  font-size: 14px;
  padding: 0;
  border: 1px solid black;
  background-color: #ffffff;
  color: black;
  &:hover,
  &:active {
    border-color: #d9000d;
  }

  ${(props) => props.theme.mixins.mobile(`
    font-size: 13px; 
    width: 100%; 
    height: 34px; 
    display: flex;
    align-items: center;
    justify-content: center;
  `)};
`;
