import styled from 'styled-components';
export const BreadcrumbsContainer = styled.div `
  padding-top: 2rem;
  ${(props) => props.theme.mixins.mobile('display: none;')};
`;
export const CategoryPageInnerContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  ${(props) => props.theme.mixins.mobile('padding: 0 0 30px 0;')};
`;
export const ProductsSectionContainer = styled.div `
  width: 1440px;
  display: flex;
  padding-top: 10px;
  ${(props) => props.theme.mixins.mobile(`
    flex-direction: column; 
    padding-top: 0;
  `)};
`;
export const LeftColumnContainer = styled.section `
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  ${(props) => props.theme.mixins.mobile('display: none;')};
  @media screen and (max-width: 1400px) {
    min-width: 250px;
    flex-grow: 0;
  }
`;
export const RightColumnContainer = styled.section `
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 60%;
  ${(props) => props.theme.mixins.mobile(`
    padding-left: 15px; 
    padding-right: 15px; 
    margin-top: 30px;
  `)};
`;
export const PaginationWrapperBottom = styled.div `
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;
export const FiltersWrapper = styled.div `
  ${(props) => props.theme.mixins.mobile('display: none;')};
`;
export const BottomButtonWrapper = styled.div `
  align-self: center;
  position: sticky;
  bottom: 30px;
  z-index: 5;
  width: 210px;
  margin: 20px;
`;
export const IconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`;
export const TextMobile = styled.button `
  ${(props) => props.theme.mixins.mobile('display: flex;')};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  display: none;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  font-weight: 700;
`;
