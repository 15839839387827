import styled from 'styled-components';
export const PictogramsWrapper = styled.section `
  display: flex;
  justify-content: center;
`;
export const PictogramsContainer = styled.div `
  display: grid;
  grid-template-columns: repeat(5, 205px);
  column-gap: 25px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 205px);
    column-gap: 130px;
    row-gap: 30px;
  }
  ${(props) => props.theme.mixins.mobile(`
    grid-template-columns: repeat(3, 210px);
    column-gap: 30px;
    row-gap: 40px;
  `)}
  @media (max-width: 720px) {
    grid-template-columns: repeat(2, 250px);
  }
  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 210px);
  }
`;
