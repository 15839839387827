import React from 'react';
import { ProductDescription } from '@@App/src/client/pages/ProductPage/ProductDescription';
import { ProductDesignContainer } from '@@Pages/ProductPage/ProductDesign/styled';
export const ProductDesign = ({ product, }) => {
    if (!product)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(ProductDesignContainer, null,
            React.createElement(ProductDescription, { title: product === null || product === void 0 ? void 0 : product.name, description: product === null || product === void 0 ? void 0 : product.description }))));
};
